import { useEffect } from "react";

const Mautic = ({ email, tags }) => {
  // set email to lowercase
  email && (email = email.toLowerCase());

  const tagString =
    tags && tags.length && email
      ? `, {'email': '${email}', 'tags':'${tags.join(",")}'}`
      : "";

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `function wpmautic_send(){
		if ('undefined' === typeof mt) {
			if (console !== undefined) {
				console.warn('WPMautic: mt not defined. Did you load mtc.js ?');
			}
			return false;
		}
		// Add the mt('send', 'pageview') script with optional tracking attributes.
		mt('send', 'pageview'${tagString});
	}

		(function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
		w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
		m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
	})(window,document,'script','https://m.usabenefitfinder.com/mtc.js','mt');

	wpmautic_send();`;

    script.async = true;
    document.body.appendChild(script);
  }, []);

  return null;
};

export default Mautic;
