const resultsOffers = [
  {
    enabled: true,
    niche: "stimulus",
    title: "Paid Clinical Trials in Your Area",
    desc: "Some Trials Pay Up To Several Thousand Dollars!",
    img: "/assets/goodlab-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1031",
  },
  {
    enabled: true,
    niche: "stimulus",
    title: "Stimulus Benefits Guide",
    desc: "Take Advantage of New Stimulus Relief Opportunities Now!",
    img: "/assets/stimmoney-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1033",
  },
  {
    enabled: false,
    niche: "survey",
    title: "FreeCash",
    desc: "Get Paid for Testing Apps, Games & Surveys! Up to $225 Each!",
    img: "/assets/freecash-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1039",
  },
  {
    enabled: true,
    niche: "disability",
    title: "Nationwide Disability Benefits",
    desc: "You May Qualify for Benefits up to $3627 Monthly!",
    img: "/assets/nationwidedisabilitybenefits-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1056",
    restrictions: {
      minAge: 53,
      maxAge: 64,
    },
  },
  {
    enabled: true,
    niche: "unclaimed",
    title: "Unclaimed Money Info",
    desc: "Unclaimed Funds Are Available! Go Here For More Info",
    img: "/assets/findunclaimedassets-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cogid=1",
  },
  {
    enabled: true,
    niche: "tort",
    title: "Injured in an Auto Accident in the last year?",
    desc: "See how much your case is worth with a FREE Legal Consultation!",
    img: "/assets/tort-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1051",
  },
  {
    enabled: true,
    niche: "senior",
    title: "Are You 62+ and Receiving Social Security Benefits?",
    desc: "Access Your Social Security Early Each Month With Charlie.",
    img: "/assets/charlie-favicon.svg",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1047",
    restrictions: {
      minAge: 62,
    },
  },
  {
    enabled: true,
    niche: "sweeps",
    title: "Want to earn Cash Back? Get the Walmart MoneyCard!",
    desc: "Terms and Conditions Apply; See Website for Details",
    img: "/assets/walmart-moneycard-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1038",
  },
  {
    enabled: true,
    niche: "grants",
    title: "Grant Aid Authority",
    desc: "Access Grant Money that NEVER HAS TO BE PAID BACK!",
    img: "/assets/grantaidauthority-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1003",
  },
  {
    enabled: true,
    niche: "loan",
    title: "$750 Platinum Card for Shopping",
    desc: "Get Yours INSTANTLY And Shop For Merchandise RIGHT NOW!",
    img: "/assets/boost-platinum-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1042",
  },
  {
    enabled: true,
    niche: "grants",
    title: "Grants Resources",
    desc: "Get FREE Grants Assistance To Get The Help You Need FAST",
    img: "/assets/grantsresources-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1046",
  },
  {
    enabled: false,
    niche: "unclaimed",
    title: "Find Unclaimed Assets",
    desc: "Explore free, official sources to identify unclaimed assets",
    img: "/assets/findunclaimedassets-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1002",
  },
  {
    enabled: true,
    niche: "debt",
    title: "2024 Debt Relief Program",
    desc: "You May Qualify For Relief. Go Here To Enroll NOW!",
    img: "/assets/cash-advance-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1007",
    restrictions: {
      state: [
        "AK",
        "AL",
        "AR",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "FL",
        "GA",
        "IL",
        "IN",
        "KY",
        "LA",
        "MA",
        "MD",
        "MI",
        "MO",
        "MS",
        "NC",
        "NE",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "PA",
        "SC",
        "SD",
        "TX",
        "TN",
        "UT",
        "VA",
        "WI",
        "WV",
      ],
      dayPart: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      hourPart: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    },
  },
  {
    enabled: false,
    niche: "loan",
    title: "Cash Advance",
    desc: "Request up to $10,000 Cash Now",
    img: "/assets/cash-advance-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1044",
    restrictions: {
      state: [
        "AL",
        "AK",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "WA",
        "WI",
        "WY",
      ],
    },
  },
  {
    enabled: true,
    niche: "senior",
    title: "Senior Benefits for Age 50+",
    desc: "Financial Secrets That Most Seniors Never Hear About",
    img: "/assets/my-senior-perks-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1025",
    restrictions: {
      minAge: 50,
    },
  },
  {
    enabled: true,
    niche: "edu",
    title: "Education Grants",
    desc: "You may qualify for up to $7,395/yr* to go back to school!",
    img: "/assets/classesandcareers-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cogid=3",
  },
  {
    enabled: false,
    niche: "loan",
    title: "Personal Loans",
    desc: "Request from $250 to $35,000 - as quick as 1 business day",
    img: "/assets/sweepspal-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1044",
  },
  {
    enabled: false,
    niche: "unclaimed",
    title: "USA Benefits Guide",
    desc: "Claim your FREE benefits guide and get the help you need!",
    img: "/assets/usabenefitsguide-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1008",
  },
  {
    enabled: false,
    niche: "grants",
    title: "Financial Assistance For You",
    desc: "Need Cash FAST? Get money or financial advice right now!",
    img: "/assets/financialassistanceforyou-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1014",
  },
  {
    enabled: false,
    niche: "grants",
    title: "Government Assistance Grants",
    desc: "FREE guide provides helpful info about how to apply for benefits",
    img: "/assets/thegovernmentassistance-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1015",
  },
  {
    enabled: false,
    niche: "grants",
    title: "Grant Assistance For You",
    desc: "You may be eligible for Financial Grant Programs",
    img: "/assets/grantsassistanceforyou-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1004",
  },
  {
    enabled: false,
    niche: "survey",
    title: "Stimulus Benefits Guide",
    desc: "Get the exclusive FREE Stimulus Benefits Guide!",
    img: "/assets/helpfulfunds-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1020",
  },
  {
    enabled: false,
    niche: "stimulus",
    title: "Inflation Relief Programs",
    desc: "Get energy bill relief, food and gas assistance, grants and more",
    img: "/assets/inflationrelief-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1029",
  },
  {
    enabled: false,
    niche: "tort",
    title: "Class Action Lawsuits",
    desc: "Act Now to Claim Your Portion of These Lawsuits!",
    img: "/assets/tort-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cogid=10",
  },
  {
    enabled: false,
    niche: "unemployed",
    title: "Unemployment Benefits Guide",
    desc: "You may be eligible for Unemployment Benefits!",
    img: "/assets/usaunemploymentguide-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1032",
    restrictions: {
      maxAge: 75,
    },
  },
  {
    enabled: false,
    niche: "unemployed",
    title: "US Unemployment Resources",
    desc: "You could be entitled to as much as $48,600 in total benefits!",
    img: "/assets/usunemploymentresources-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1017",
    restrictions: {
      maxAge: 75,
    },
  },
  {
    enabled: false,
    niche: "unemployed",
    title: "My USA Loans",
    desc: "Get up to $5,000 as soon as the next business day!",
    img: "/assets/myusaloans-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1041",
    restrictions: {
      maxAge: 90,
      state: [
        "AL",
        "AK",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VA",
        "WA",
        "WI",
        "WY",
      ],
    },
  },
  {
    enabled: false,
    niche: "survey",
    title: "Survey Voices",
    desc: "Make Money Sharing Your Opinion. It's That Simple!",
    img: "/assets/surveyvoices-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1027",
  },
  {
    enabled: false,
    niche: "sweeps",
    title: "Exclusive Prize!",
    desc: "Claim your chance to win a $1000 Walmart Gift Card",
    img: "/assets/prize-box-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1038",
  },
  {
    enabled: false,
    niche: "sweeps",
    title: "$50,000 Weekly Sweepstakes",
    desc: "Enter Now for Your Weekly Chance to Win $50,000!",
    img: "/assets/sweepspal-icon.png",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1011",
  },
  {
    enabled: false,
    niche: "survey",
    title: "InboxDollars",
    desc: "Get Cash for doing fun online activities + Free $5 signup bonus!",
    img: "/assets/inboxdollars-icon.jpg",
    url: "https://c.usabenefitfinder.com/base3.php?cloid=1019",
  },
];

export default resultsOffers;
