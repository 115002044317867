import React from "react";
import {
  Typography,
  Button,
  Box,
  Link,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CpvLab from "./CpvLab";

const UnclaimedApp = ({ campaignId, landingId }) => {
  // set CTA URL for multi-option campaign or single offer campaign
  const ctaUrl1 =
    campaignId === 18
      ? "https://lab.securtrk.com/base2.php?cogid=1"
      : "https://lab.securtrk.com/base2.php";
  const ctaUrl2 =
    campaignId === 18
      ? "https://lab.securtrk.com/base2.php?cogid=2"
      : "https://lab.securtrk.com/base2.php";

  return (
    <Box className="App-content">
      <Typography variant="h4" align="center" sx={{ fontWeight: 500 }}>
        DO YOU NEED MONEY?
      </Typography>
      <Box
        sx={{
          p: 1,
          borderRadius: 3,
          backgroundColor: "rgba(245, 245, 220, 0.8)",
        }}
      >
        <Typography variant="h5" align="center" sx={{ m: 1 }}>
          THERE IS{" "}
          <b>
            <u>OVER $58 BILLION</u>
          </b>{" "}
          IN UNCLAIMED FUNDS IN THE USA.
        </Typography>
        <Typography align="center" sx={{ m: 1 }}>
          These assets are waiting to be given back to the rightful owners.
          Common sources include:
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <List sx={{ p: 0 }}>
              <ListItem alignItems="flex-start" sx={{ py: 0.4 }}>
                <ListItemIcon sx={{ m: 0 }}>
                  <AttachMoneyIcon color="success" />
                </ListItemIcon>
                <b>Unclaimed IRS checks</b>
              </ListItem>
              <ListItem alignItems="flex-start" sx={{ py: 0.4 }}>
                <ListItemIcon sx={{ m: 0 }}>
                  <AttachMoneyIcon color="success" />
                </ListItemIcon>
                <b>Undelivered payroll checks</b>
              </ListItem>
              <ListItem alignItems="flex-start" sx={{ py: 0.4 }}>
                <ListItemIcon sx={{ m: 0 }}>
                  <AttachMoneyIcon color="success" />
                </ListItemIcon>
                <b>Abandoned bank accounts and stock holdings</b>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List sx={{ p: 0 }}>
              <ListItem alignItems="flex-start" sx={{ py: 0.4 }}>
                <ListItemIcon sx={{ m: 0 }}>
                  <AttachMoneyIcon color="success" />
                </ListItemIcon>
                <b>Unclaimed insurance payouts</b>
              </ListItem>
              <ListItem alignItems="flex-start" sx={{ py: 0.4 }}>
                <ListItemIcon sx={{ m: 0 }}>
                  <AttachMoneyIcon color="success" />
                </ListItemIcon>
                <b>Forgotten retirement benefits</b>
              </ListItem>
              <ListItem alignItems="flex-start" sx={{ py: 0.4 }}>
                <ListItemIcon sx={{ m: 0 }}>
                  <AttachMoneyIcon color="success" />
                </ListItemIcon>
                <b>and many more sources!</b>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Typography align="center" sx={{ p: 1 }}>
          Explore free, official resources to identify unclaimed assets and see
          how much you could claim:
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
        <Link href={ctaUrl1} sx={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="success"
            size="large"
            startIcon={<SearchIcon />}
            sx={{ borderRadius: 5 }}
          >
            VIEW AVAILABLE FUNDS
          </Button>
        </Link>
      </Box>
      <Typography variant="h4" align="center" sx={{ mt: 4, fontWeight: 500 }}>
        DID YOU KNOW?
      </Typography>
      <Box
        sx={{
          p: 1,
          borderRadius: 3,
          backgroundColor: "rgba(245, 245, 220, 0.8)",
        }}
      >
        <Typography variant="h5" align="center" sx={{ m: 1 }}>
          EVERY YEAR THE U.S. GOVERNMENT GIVES AWAY <u>HUNDREDS OF BILLIONS</u>{" "}
          OF DOLLARS IN GRANTS AND OTHER FINANCIAL AID THAT YOU MAY QUALIFY FOR!
        </Typography>
        <Typography align="center">
          You can use the money for education, small businesses, repairs,
          catching up on bills and more.
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
        <Link href={ctaUrl2} sx={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="success"
            size="large"
            endIcon={<DoubleArrowIcon />}
            sx={{ borderRadius: 5 }}
          >
            CHECK YOUR ELIGIBILITY
          </Button>
        </Link>
      </Box>
      <Typography align="center">
        <b>NOTE: These funds do NOT need to be paid back and are not a loan.</b>
      </Typography>
      <CpvLab
        campaignId={campaignId}
        landingId={landingId}
        domain="lab.securtrk.com"
      />
    </Box>
  );
};

export default UnclaimedApp;
