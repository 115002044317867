import React from "react";

const Terms = () => {
  return (
    <div className="App-content">
      <div class="_header_4a3ac">
        <h1>Terms</h1>
        <div class="_meta_4a3ac">Last Updated: December 8, 2022</div>
      </div>
      <div class="_body_4a3ac">
        <div class="_richText_7afec">
          <p>
            You are visiting a website owned by Elite Market Force, LLC. By
            using this website, you are entering into a legal agreement to abide
            by the terms of service you see here, and you are agreeing that you
            have read and fully understand these terms of service.
          </p>
          <p>
            These Terms of Service (“Terms of Service”) apply to the websites
            operated by Elite Market Force, LLC and any of its affiliates,
            including, but not limited to the website usabenefitfinder.com
            ("UBF") (collectively, “Websites” or each individually “Website”) on
            which these Terms of Service are displayed. These Terms of Service
            also apply to your use of any goods, facilities, or services offered
            through the Websites, including, but not limited to any online
            content, tools, and services related to the lending or insurance
            markets, whether accessed on the Websites or through third-party
            websites, mobile sites, and/or applications (collectively, such
            services, including any new features and applications, and the Site,
            the “Services.”) By accessing, browsing, or using the Websites, you
            acknowledge that you understand, accept, and agree to be bound by
            this Terms of Service, as well as our Privacy Policy, which is
            incorporated into this Terms of Service by reference. You agree
            that, to the fullest extent required by the law of any state, you
            have been provided with, have received, and are agreeing to all
            disclosure and consent requirements. Certain Services may include
            additional terms and by agreeing to proceed with any such Services
            you acknowledge that you have read, understand, and agree to be
            bound by any additional terms displayed or referenced that apply to
            that Service.
          </p>
          <ul>
            <li>
              For purposes of this Agreement, “you,” or “your” means the
              person(s) using the Websites and/or the Services, and any
              person(s) for whom such person(s) are acting as an agent with
              respect to the Websites and/or the Services.
            </li>
            <li>
              “Elite Market Force”, “EMF,” “us,” or “we” means Elite Market
              Force, LLC and any of its affiliates.
            </li>
          </ul>
          <p>
            We reserve the right, in our sole discretion, to change or modify
            portions of these Terms of Service, in whole or in part, at any time
            without further notice. You should periodically visit this page to
            review the current Terms of Service so you are aware of any
            revisions to which you are bound. If we make any revisions, we will
            post them on this page and will indicate at the top of this page the
            date these Terms of Service were last revised. We will also notify
            you of any material changes through a pop-up notice, email, or other
            reasonable means.
            <u>
              Your continued use of the Services after any such changes
              constitutes your acceptance of the new Terms of Service. If you do
              not agree to abide by these or any future Terms of Service, do not
              use or access (or continue to use or access) the Services.
            </u>
            The Terms of Service constitute a legally binding agreement between
            you and Elite Market Force. The Websites are intended for
            individuals who are at least 18 years of age and are accessing the
            internet from a physical location within the United States of
            America. By using or accessing the Websites, you acknowledge that
            are you at least 18 years of age and are accessing the internet from
            a physical location within the United States of America. We do not
            knowingly collect the information of anyone under the age of 18 or
            from outside the United States of America. If you arrived on the
            Site after having been re-directed or otherwise clicking on another
            website, you agree that these Terms of Service shall govern your use
            of this Site.
          </p>
          <p>
            In addition, when using certain services, you will be subject to any
            additional terms applicable to such services that may be posted on
            or in connection with the Services from time to time, including,
            without limitation, Elite Market Force's{" "}
            <a href="https://usabenefitfinder.com/privacy">Privacy Policy</a>,
            which also governs your use of this Site. All such terms are hereby
            incorporated by reference into these Terms of Service. If there is
            any conflict between such additional terms and these Terms of
            Service, the additional terms will supplement or amend these Terms
            of Service, but only with respect to the matters governed by the
            additional terms.
          </p>
          <h2>Access and Use of the Services</h2>
          <p>
            <strong>Services Description:</strong> The Services are designed to
            provide prospective borrowers or insureds with information about
            loan products, insurance products, lenders insurers, and/or service
            providers, and the lending and insurance markets through the
            Services' content and tools. Elite Market Force provides such
            information, content, and tools as part of the Services but is not a
            lender or broker itself, or an insurance company, insurance agent,
            or insurance broker itself. The Services are a tool that prospective
            borrowers or insureds can use to comparison shop for loans and
            insurance products. Any transaction to which you are a party is
            solely transacted between you and the applicable lender, insurer,
            and/or service provider and is not a part of the Services.
            Additionally, when you submit your information through our Websites,
            one of our partners may return multiple products for you. Elite
            Market Force will occasionally highlight a product or offer as a
            Featured Offer. We select which offer to feature based on a number
            of factors, including, but not limited to, your stated preferences,
            compensation we receive from the partner, and the order of products
            returned by our partner. By displaying this Featured Offer, we are
            neither making a recommendation of the product nor endorsing the
            product.
          </p>
          <p>
            Elite Market Force also provides lenders, insurers, and/or service
            providers with information about prospective borrowers and/or
            insureds, as described in our Privacy Policy.
          </p>
          <p>
            <strong>Your Registration Obligations:</strong> You may be required
            to register with Elite Market Force in order to access and use
            certain features of the Services. If you choose to register for the
            Services, you agree to provide and maintain true, accurate, current,
            and complete information about yourself as prompted by the Services'
            registration form or one of our tools or forms that appears on a
            third-party website, mobile site, or application. Registration data,
            information that you enter into our tools or forms, and certain
            other information about you are governed by our{" "}
            <a href="https://usabenefitfinder.com/privacy">Privacy Policy</a>.
            Elite Market Force will not be liable for any loss or damage arising
            from your failure to comply with this section.
          </p>
          <p>
            <strong>Modifications to Services:</strong> Elite Market Force
            reserves the right to modify or discontinue, temporarily or
            permanently, the Services (or any part thereof) with or without
            notice. You agree that Elite Market Force will not be liable to you
            or to any third party for any modification, suspension, or
            discontinuance of the Services.
          </p>
          <p>
            <strong>General Practices Regarding Use and Storage:</strong> You
            acknowledge that Elite Market Force may establish general practices
            and limits concerning use of the Services, including without
            limitation, the maximum period of time that data or other content
            will be retained by the Services and the maximum storage space that
            will be allotted to Elite Market Force's servers on your behalf. You
            agree that Elite Market Force has no responsibility or liability for
            the deletion of or failure to store any data or other content
            maintained by or uploaded to the Services. You further acknowledge
            that Elite Market Force reserves the right to change these general
            practices and limits at any time, in our sole discretion, with or
            without notice. You additionally acknowledge that Elite Market Force
            may utilize certain internal or third-party services to record your
            session and activity while visiting its Websites, including, but not
            limited to, the use of Jornaya to record a LeadId and to capture
            data that was entered on a website form by you during the Website
            visit on a specific date and time as well as the content of the
            Website itself to which you were exposed and actions that you took.
          </p>
          <p>
            <strong>Mobile Services:</strong> The Services include certain
            services that may be available via a mobile device, including (i)
            the ability to submit information or upload content to the Services
            via a mobile device and (ii) the ability to browse the Services and
            the Site from a mobile device (collectively, the “Mobile Services”).
            To the extent you access the Services through a mobile device, your
            wireless service carrier's standard charges, data rates, and other
            fees may apply. In addition, using certain Mobile Services may be
            prohibited or restricted by your carrier, and not all Mobile
            Services may work with all carriers or devices.
          </p>
          <p>
            By using the Mobile Services and/or providing your mobile telephone
            number to us through the Services, you agree that you are providing
            express written consent for Elite Market Force to share your
            information with its{" "}
            <a href="https://usabenefitfinder.com/partner-list">partners</a> and
            that Elite Market Force and its partners are authorized to contact
            you at the telephone number you have provided, including by
            telephone call, SMS, MMS, text message, shortcode 89330, or other
            electronic means to your mobile device, including through the use of
            automated dialing or prerecorded or artificial messages, even if
            your telephone number is listed on Federal, State, or other
            jurisdictions Do Not Call List or have otherwise opted in to the
            National Do Not Call List, any state equivalent Do Not Call List or
            the internal Do Not Call List, and that normal message and data
            rates may apply. Message frequency varies. To opt out of receiving
            future messages, please reply "STOP" to any message. For additional
            support or information, please email{" "}
            <a href="mailto:info@elitemarketforce.com">
              info@elitemarketforce.com
            </a>
            .
          </p>
          <p>
            In the event you change or deactivate your mobile telephone number,
            you agree to promptly update information that you have submitted to
            us to ensure that your messages are not sent to the person that
            acquires your old number.
          </p>
          <h2>
            Your E-mail Address and Data; Our Privacy Policy; Data Transmittal
          </h2>
          <p>
            When you provide your e-mail address, name, or other information to
            us in connection with your use or access to our Websites or
            Services, any other service or tool provided on the Websites or
            otherwise, you agree to allow the Websites and any of its affiliated
            websites and{" "}
            <a href="https://usabenefitfinder.com/partner-list">partners</a> to
            add your e-mail address, name, or other information provided to our
            database of users. You may receive one or more promotional e-mails
            from either the Site or a website of one of Elite Market Force's
            affiliates or partners. You are welcome to opt not to receive such
            promotional e-mails from the Site or such affiliates' or partners'
            websites at any time. Please review our{" "}
            <a href="https://usabenefitfinder.com/privacy">Privacy Policy</a>{" "}
            for more information regarding our email and other data collection
            practices and safeguards, and how to opt not to receive such emails.
            Your use of the Site signifies your acknowledgment of, and agreement
            with, our{" "}
            <a href="https://usabenefitfinder.com/privacy">Privacy Policy</a>.
            We adhere to strong principles of privacy. You agree that we may
            access and use your user-contributed content in accordance with
            these Terms of Service or our{" "}
            <a href="https://usabenefitfinder.com/privacy">Privacy Policy</a>,
            and we agree that we will only disclose your user-contributed
            content in accordance with these Terms of Service and our{" "}
            <a href="https://usabenefitfinder.com/privacy">Privacy Policy</a>.
          </p>
          <p>
            <strong>Additional Electronic Notices:</strong> By using the
            Services, you further agree that we may communicate with you
            electronically via email regarding security, privacy, and
            administrative issues relating to your use of the Services. If we
            learn of a security system's breach, we may attempt to notify you
            electronically by posting a notice on the Services or sending an
            email to you. You may have a legal right to receive this notice in
            writing. To receive free written notice of a security breach (or to
            withdraw your consent from receiving electronic notice), please
            notify us at{" "}
            <a href="mailto:info@elitemarketforce.com">
              info@elitemarketforce.com
            </a>
            .
          </p>
          <h2>Conditions of Use</h2>
          <p>
            <strong>User Conduct:</strong> You are solely responsible for all
            information, data, text, software, messages, or other materials that
            you submit or upload to the Services or otherwise use via the
            Services (“User Content”). Elite Market Force reserves the right to
            investigate and take appropriate legal action against anyone who, in
            Elite Market Force's sole discretion, violates this provision,
            including without limitation, removing the offending content from
            the Services, blocking such violator's access to the Services, and
            reporting the violator to law enforcement authorities. You agree to
            not use the Services to:
          </p>
          <p>
            a) email or otherwise submit or upload any content that (i)
            infringes any intellectual property or other proprietary rights of
            any party; (ii) you do not have a right to submit or upload under
            any law or under contractual or fiduciary relationships; (iii)
            contains software viruses or any other computer code, files, or
            programs designed to interrupt, destroy, or limit the functionality
            of any computer software or hardware or telecommunications
            equipment; (iv) poses or creates a privacy or security risk to any
            person; (v) constitutes unsolicited or unauthorized advertising,
            promotional materials, commercial activities and/or sales, “junk
            mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,”
            “sweepstakes,” or any other form of solicitation; (vi) is unlawful,
            harmful, threatening, abusive, harassing, tortious, excessively
            violent, defamatory, vulgar, obscene, pornographic, libelous,
            invasive of another's privacy, hateful racially, ethnically, or
            otherwise objectionable; or (vii) in the sole judgment of Elite
            Market Force, is objectionable or which restricts or inhibits any
            other person from using or enjoying the Services, or which may
            expose Elite Market Force or our users to any harm or liability of
            any type;
          </p>
          <p>
            b) interfere with or disrupt the Services or servers or networks
            connected to the Services, or disobey any requirements, procedures,
            policies, or regulations of networks connected to the Services;
          </p>
          <p>
            c) violate any applicable local, state, national, or international
            law, or any regulations having the force of law;
          </p>
          <p>
            d) impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity;
          </p>
          <p>e) solicit personal information;</p>
          <p>
            f) harvest or collect email addresses or other contact information
            of other users from the Services by electronic or other means for
            the purposes of sending unsolicited emails or other unsolicited
            communications;
          </p>
          <p>
            g) commit, further, or promote any criminal activity or enterprise;
            or
          </p>
          <p>
            h) obtain or attempt to access or otherwise obtain any materials or
            information through any means not intentionally made available or
            provided for through the Services.
          </p>
          <p>
            <strong>
              Special Notice for International Use; Export Controls:
            </strong>
            Software (defined in the section on “Intellectual Property Rights”
            below) available in connection with the Services and the
            transmission of applicable data, if any, is subject to United States
            export controls. No Software may be downloaded from the Services or
            otherwise exported or re-exported in violation of U.S. export laws.
            Your use of the Software is at your sole risk. Recognizing the
            global nature of the Internet, you agree to comply with all local
            rules and laws regarding your use of the Services, including as it
            concerns online conduct and acceptable content.
          </p>
          <p>
            <strong>Commercial Use Not Permitted:</strong> Unless otherwise
            expressly authorized herein or in the Services, you agree not to
            display, distribute, license, perform, publish, reproduce,
            duplicate, copy, create derivative works from, modify, sell, resell,
            exploit, transfer, or upload for any commercial purposes, any
            portion of the Services, use of the Services, or access to the
            Services. The Services are for your personal use only.
          </p>
          <h2>Intellectual Property Rights</h2>
          <p>
            <strong>Service Content, Software, and Trademarks:</strong> You
            acknowledge and agree that the Services may contain content or
            features (“Service Content”) that are protected by copyright,
            patent, trademark, trade secret, or other proprietary rights and
            laws. Except as expressly authorized by Elite Market Force, you
            agree not to modify, copy, frame, scrape, rent, lease, loan, sell,
            distribute, or create derivative works based on the Services or the
            Service Content, in whole or in part, except that the foregoing does
            not apply to your own User Content (as defined below) that you
            legally submit or upload to the Services. You may not remove or
            alter any proprietary notices or marks on the Services or any
            portion thereof. In connection with your use of the Services you
            will not engage in or use any data mining, robots, scraping, or
            similar data gathering or extraction methods. Any use of the
            Services or the Service Content other than as specifically
            authorized herein is strictly prohibited. The technology and
            software underlying the Services or distributed in connection
            therewith is the property of Elite Market Force, our affiliates, and
            our partners (the “Software”). You agree not to copy, modify, create
            a derivative work of, reverse engineer, reverse assemble, or
            otherwise attempt to discover any source code, sell, assign,
            sublicense, or otherwise transfer any right in the Software. Any
            rights not expressly granted herein are reserved by Elite Market
            Force.
          </p>
          <p>
            The Elite Market Force and USABenefitFinder names and logos are
            trademarks and service marks of Elite Market Force (collectively the
            “Elite Market Force Trademarks”). Other company, product, and
            service names and logos used and displayed via the Services may be
            trademarks or service marks of their respective owners who may or
            may not endorse or be affiliated with or connected to Elite Market
            Force. Nothing in these Terms of Service or the Services should be
            construed as granting, by implication, estoppel, or otherwise, any
            license or right to use any of Elite Market Force trademarks
            displayed on the Services, without our prior written permission in
            each instance. All goodwill generated from the use of the Elite
            Market Force trademarks will inure to our exclusive benefit.
          </p>
          <p>
            <strong>Third-Party Material:</strong> Under no circumstances will
            Elite Market Force be liable in any way for any content or materials
            of any third parties (including users), including, but not limited
            to, for any errors or omissions in any content, or for any loss or
            damage of any kind incurred as a result of the use of any such
            content. You acknowledge that Elite Market Force does not pre-screen
            content from third parties, but that Elite Market Force and our
            designees will have the right (but not the obligation) in our sole
            discretion to refuse or remove any content that is available via the
            Services. Without limiting the foregoing, Elite Market Force and our
            designees will have the right to remove any content that violates
            these Terms of Service or is deemed by Elite Market Force, in our
            sole discretion, to be otherwise objectionable. You agree that you
            must evaluate, and bear all risks associated with, the use of any
            content, including any reliance on the accuracy, completeness, or
            usefulness of such content.
          </p>
          <p>
            <strong>User Content Transmitted Through the Service:</strong> With
            respect to your User Content, you represent and warrant that you own
            all right, title, and interest in and to such User Content,
            including, without limitation, all copyright and rights of publicity
            contained therein. By uploading any User Content, you hereby grant
            and will grant Elite Market Force and our affiliated companies and
            partners a nonexclusive, worldwide, royalty free, fully paid up,
            transferable, sublicensable, perpetual, irrevocable license to copy,
            display, upload, perform, distribute, store, modify and otherwise
            use your User Content in connection with the operation of the
            Services, in any form, medium, or technology now known or later
            developed.
          </p>
          <p>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information about the Services
            (“Submissions”) provided by you to Elite Market Force are
            non-confidential and Elite Market Force will be entitled to the
            unrestricted use and dissemination of these Submissions for any
            purpose, commercial or otherwise, without acknowledgment or
            compensation to you.
          </p>
          <p>
            You acknowledge and agree that Elite Market Force may preserve
            content and may also disclose content if required to do so by law or
            in the good faith belief that such preservation or disclosure is
            reasonably necessary to: (a) comply with legal process, applicable
            laws, or government requests; (b) enforce these Terms of Service;
            (c) respond to claims that any content violates the rights of third
            parties; or (d) protect the rights, property, or personal safety of
            Elite Market Force, our users, and the public. You understand that
            the technical processing and transmission of the Services, including
            your content, may involve (a) transmissions over various networks;
            and (b) changes to conform and adapt to technical requirements of
            connecting networks or devices.
          </p>
          <p>
            You acknowledge, agree, and authorize that (a) your information may
            be sent by us to lenders, insurance companies, brokers, and/or
            service providers on your behalf, (b) your information may be used
            in order to make sure you are who you say you are, and to determine
            how much debt you currently have in order to determine your
            debt-to-income ratio,
            <strong>
              (c) such lenders, insurance companies, and/or service providers,
              to the extent permitted by applicable law, may obtain consumer
              reports and related information about you from one or more
              consumer reporting agencies, such as TransUnion, Experian, and
              Equifax,
            </strong>
            and (d) your information may be used to make certain
            prequalification and other credit decisions or decisions regarding
            insurance products and/or their issuance thereof.
          </p>
          <h2>Third Party Websites</h2>
          <p>
            The Services may provide, or third parties may provide, links or
            other access to other sites and resources on the Internet. Elite
            Market Force has no control over and is not responsible for such
            sites and resources, and links to such sites and resources do not
            indicate Elite Market Force's endorsement of or affiliation with
            such sites and resources. You further acknowledge and agree that
            Elite Market Force will not be responsible or liable, directly or
            indirectly, for any damage or loss caused, or alleged to be caused
            by, or in connection with, use of or reliance on any content,
            events, goods, or services available on or through any such site or
            resource. Any dealings you have with third parties found while using
            the Services are between you and the third party, and you agree that
            Elite Market Force is not liable for any loss or claim that you may
            have against any such third party.
          </p>
          <h2>Third Party Services</h2>
          <p>
            As provided in these Terms of Service, Elite Market Force may
            utilize the services of certain third parties in an effort to
            provide you with better Services or more user friendly Websites. One
            such third party service that Elite Market Force uses is Jornaya.
            Jornaya is used to record a LeadId and to capture data that was
            entered on a website form by you during the Website visit on a
            specific date and time as well as the content of the Website itself
            to which you were exposed and actions that you took. To ensure that
            Jornaya's services work as intented, Elite Market Force may utilize
            a proxy server or other technology to send information to Jornaya,
            particularly in the case where you employ an ad blocker or browser
            that automatically uses such technology.
          </p>
          <h2>Limitation of Liability</h2>
          <p>
            <strong>
              IN NO EVENT WILL Elite Market Force, OR ITS PARTNERS, OFFICERS,
              DIRECTORS, CONSULTANTS, CONTRACTORS, AGENTS, AND/OR EMPLOYEES
              (COLLECTIVELY, THE “EMF GROUP”), OR ANY THIRD-PARTY PROVIDER OF A
              SERVICE OR TOOL OFFERED ON ANY SITE OF A MEMBER OF THE EMF GROUP
              (EACH A “THIRD-PARTY PROVIDER”), BE LIABLE FOR ANY LOST REVENUES
              OR PROFITS OR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR
              PUNITIVE DAMAGES ARISING OUT OF, BASED ON, OR RESULTING FROM (A)
              OUR WEBSITES, (B) THE SERVICES, (C) THESE TERMS OF SERVICE, (D)
              ANY BREACH OF THESE TERMS OF SERVICE BY YOU OR A THIRD PARTY, (E)
              USE OF THE WEBSITES, TOOLS, OR SERVICES WE PROVIDE, OR ANY THIRD
              PARTY PROVIDER PROVIDES, RELATED TO THE BUSINESS WE OPERATE ON THE
              WEBSITES, BY YOU OR ANY THIRD PARTY, (F) ANY USER CONTENT, (G)
              INTERACTION BETWEEN OUR WEBSITES AND ANY THIRD PARTY SITE,
              INCLUDING WITHOUT LIMITATION A SOCIAL MEDIA SITE, FACILITATED BY A
              TOOL OR SERVICE ON OUR WEBSITES, AND/OR (H) ANY ACTUAL OR
              ATTEMPTED COMMUNICATION OR TRANSACTION, INCLUDING WITHOUT
              LIMITATION, ANY PAYMENT TRANSACTION (EVEN IF WE OR ANY THIRD PARTY
              PROVIDER RECEIVES A FEE IN CONNECTION THEREWITH) BETWEEN USERS, IN
              EACH CASE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO
              WHETHER THE DAMAGES ARISE FROM (1) BREACH OF CONTRACT, (2) BREACH
              OF WARRANTY, (3) STRICT LIABILITY, (4) TORT, (5) NEGLIGENCE, OR
              (6) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM EXTENT SUCH
              EXCLUSIONS AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW.
            </strong>
          </p>
          <p>
            <strong>
              IF YOU ARE DISSATISFIED WITH THE WEBSITES, YOU DO NOT AGREE WITH
              ANY PART OF THE TERMS OF SERVICE, OR HAVE ANY OTHER DISPUTE OR
              CLAIM WITH OR AGAINST US, ANY THIRD-PARTY PROVIDER, OR ANY USER OF
              THE WEBSITES WITH RESPECT TO THESE TERMS OF SERVICE OR THE SITE,
              THEN YOUR SOLE AND EXCLUSIVE REMEDY AGAINST US IS TO DISCONTINUE
              USING THE WEBSITES. IN ALL EVENTS, OUR LIABILITY, AND THE
              LIABILITY OF ANY MEMBER OF THE EMF GROUP, TO YOU OR ANY THIRD
              PARTY IN ANY CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE
              WEBSITES OR SERVICES IS LIMITED TO $100.00 IN THE AGGREGATE FOR
              ALL CLAIMS.
            </strong>
          </p>
          <h2>Disclaimers</h2>
          <p>
            <strong>
              THE WEBSITES, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS,
              MATERIALS, AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH
              THE WEBSITES, ARE PROVIDED "AS IS." TO THE FULLEST EXTENT
              PERMISSIBLE BY LAW, WE MAKE NO REPRESENTATIONS OR WARRANTIES OF
              ANY KIND WHATSOEVER FOR THE CONTENT ON THE WEBSITES OR THE
              SERVICES, FOR ANY PRODUCTS OR SERVICES OR HYPERTEXT LINKS TO THIRD
              PARTIES, OR FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE
              TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE WEBSITES OR ANY
              LINKED SITE, EVEN IF WE BECOME AWARE OF ANY SUCH BREACHES.
              FURTHER, WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES,
              INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY. WE DO NOT WARRANT
              THAT THE FUNCTIONS CONTAINED ON THE WEBSITES OR ANY MATERIALS OR
              CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR FREE,
              THAT DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITES OR THE SERVER
              THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS. YOU ACKNOWLEDGE AND AGREE THAT ANY TRANSMISSION TO AND
              FROM OUR WEBSITES ARE NOT CONFIDENTIAL AND YOUR COMMUNICATIONS OR
              USER-CONTRIBUTED CONTENT MAY BE READ OR INTERCEPTED BY OTHERS. YOU
              FURTHER ACKNOWLEDGE AND AGREE THAT BY SUBMITTING COMMUNICATIONS OR
              USER CONTENT TO US AND BY POSTING INFORMATION ON OUR WEBSITES, NO
              CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER
              RELATIONSHIP IS CREATED BETWEEN YOU AND US OTHER THAN PURSUANT TO
              THESE TERMS OF SERVICE.
            </strong>
          </p>
          <h2>Indemnity and Release</h2>
          <p>
            You agree to release, indemnify, and hold Elite Market Force and our
            affiliates and partners, and our and their officers, employees,
            directors, contractors, and agents harmless from any from any and
            all losses, damages, judgments, expenses, including reasonable
            attorneys' fees, rights, claims, actions of any kind and injury
            (including death) arising out of or relating to your use of the
            Services, any User Content, your connection to the Services, your
            violation of these Terms of Service or your violation of any rights
            of another. If you are a California resident, you waive California
            Civil Code Section 1542, which says: “A general release does not
            extend to claims that the creditor or releasing party does not know
            or suspect to exist in his or her favor at the time of executing the
            release and that, if known by him or her, would have materially
            affected his or her settlement with the debtor or released party.”
            If you are a resident of another jurisdiction, you waive any
            comparable statute or doctrine.
          </p>
          <h2>Disputes; Arbitration</h2>
          <p>
            Elite Market Force's right to amend these Terms of Service, in whole
            or in part, at any time as set forth above does not apply to this
            "Disputes; Arbitration" section. The version of this “Disputes;
            Arbitration” section in effect on the date you last accepted the
            Terms of Service controls.
          </p>
          <p>
            Elite Market Force is committed to customer satisfaction, so if you
            have a problem or dispute, we will try to resolve your concerns. But
            if we are unsuccessful, you may pursue claims as explained in this
            section. You agree to give us an opportunity to resolve any disputes
            or claims relating in any way to Elite Market Force, its employees
            or agents, the Site, any dealings with us or with our customer
            service, any services or products provided, including the Services,
            any representations made by us, or our{" "}
            <a href="https://usabenefitfinder.com/privacy">Privacy Policy</a>{" "}
            (“Claims”) by contacting us. If we are not able to resolve your
            Claims within 60 days, you may seek relief through arbitration as
            set forth below.
          </p>
          <p>
            <strong>
              Any and all Claims will be resolved by binding arbitration, rather
              than in court.
            </strong>
            This includes any Claims you assert against us or any companies
            offering products or services through us or on behalf of us (which
            are beneficiaries of this arbitration agreement). This also includes
            any Claims that arose before, or after, you accepted these Terms of
            Service, regardless of whether prior versions of the Terms of
            Service required arbitration.
          </p>
          <p>
            <strong>
              There is no judge or jury in arbitration, and court review of an
              arbitration award is limited. However, an arbitrator can award on
              an individual basis the same damages and relief as a court
              (including statutory damages, attorneys' fees, and costs), and
              must follow and enforce these Terms of Service as a court would.
            </strong>
          </p>
          <p>
            Arbitrations will be conducted by the American Arbitration
            Association (“AAA”) under its rules, including the AAA Arbitration
            Consumer Rules (together, the “AAA Rules”). Payment of all filing,
            administration, and arbitrator fees will be governed by the AAA's
            Rules, except as provided in this section. If your total Claims seek
            less than $10,000, we will reimburse you for filing fees you pay to
            the AAA and will pay the arbitrator's fees. You may choose to have
            an arbitration conducted by telephone, based on written submissions,
            or in person in the state where you live or at another mutually
            agreed upon location.
          </p>
          <p>
            By agreeing to arbitration under the AAA Rules, the parties agree,
            among other things, that the arbitrator, and not any federal, state,
            or local court or agency, shall have the exclusive power to rule on
            any objections with respect to the existence, scope, or validity of
            the arbitration agreement or to the arbitrability of any claim or
            counterclaim.
          </p>
          <p>
            To begin an arbitration proceeding, you must send a letter
            requesting arbitration and describing your Claims to “Legal: Elite
            Market Force, LLC, 109 E 17th Street #25, Cheyenne, WY 82001. If we
            request arbitration against you, we will give you notice at the
            email address or street address you have provided. The AAA's Rules
            and filing instructions are available at www.adr.org.
          </p>
          <p>
            <strong>
              Any and all proceedings to resolve Claims will be conducted only
              on an individual basis and not in a class, consolidated, or
              representative action.
            </strong>
            If for any reason a Claim proceeds in court rather than in
            arbitration we each waive any right to a jury trial. The Federal
            Arbitration Act and federal arbitration law apply to these Terms of
            Service. An arbitration decision may be confirmed by any court with
            competent jurisdiction.
          </p>
          <h2>General</h2>
          <p>
            As noted above, these Terms of Service are governed by the Federal
            Arbitration Act, 9 U.S.C. § 1 et seq. (“FAA”), AAA Rules, federal
            arbitration law, and the laws of the State of Colorado, without
            regard to conflict of laws principles. It is the intent of the
            parties that the FAA and AAA Rules shall preempt all state laws to
            the fullest extent permitted by law.
          </p>
          <p>
            Our relationship is that of independent contractors, and no agency,
            partnership, joint venture, employee-employer, or
            franchiser-franchisee relationship is intended or created by these
            Terms of Service or your use of the Site.
          </p>
          <p>
            Except as explicitly stated otherwise, any notices to us shall be
            given by postal mail to:
          </p>
          <p>
            Elite Market Force, LLC
            <br />
            Attn: Legal Department
            <br />
            109 E 17th Street #25
            <br />
            Cheyenne, WY 82001
          </p>
          <h2>Terms Applicable to Insurance Request Services</h2>
          <p>
            As noted above, Elite Market Force is not an insurance company or a
            licensed insurance agency or broker and does not provide insurance
            quotes. The information Elite Market Force provides is not intended
            to take the place of professional advice from a licensed insurance
            agent nor does Elite Market Force provide any evaluation of the
            insurance providers or their policies. Elite Market Force recommends
            that all consumers consult with a licensed insurance agent before
            purchasing any insurance policy.
          </p>
          <p>
            Elite Market Force does not sell any insurance or bind coverage on
            its Websites; instead, Elite Market Force provides a service to help
            others offer for sale and sell insurance and other authorized
            products to you and potential consumers (each, a "Prospect") who
            have visited Elite Market Force's Websites and applied to receive
            insurance or other authorized quotations from a third-party
            provider. Elite Market Force may, through its Websites, and with
            each Prospect's authorization, collect certain information about any
            Prospect and sell the provided information to various third-party
            providers. As a result, the quality, safety or legality of the
            products offered by any third-party provider for sale, the truth or
            accuracy of the representations regarding such products, the ability
            of the third-party providers to offer or sell such products, or the
            ability of Prospects to pay for the products offered are solely the
            responsibility of the users of this Website, including Prospects and
            third-party providers. Elite Market Force is an independent
            contractor for all purposes, and Elite Market Force is not
            responsible in any way for the conduct of any Prospect or
            third-party provider. Nor does Elite Market Force endorse or
            recommend any third-party provider nor any companies or insurance
            policies. Elite Market Force does not provide insurance, tax, legal
            or any other financial advice. Elite Market Force does not guarantee
            that any of the third-party providers to whom it forwards any
            Prospect's application will contact such Prospect or agree to
            provide the Prospect with the desired coverage, or any coverage. If
            you are a Prospect and you would like personal advice or specific
            policy recommendations, please consult with an insurance agent,
            broker, or other qualified professional.
          </p>
          <h2>Terms Applicable to Mortgage Loan Request Services</h2>
          <p>
            We provide multiple mortgage request services that you can use to
            comparison shop for mortgage products. The first option offers you
            the option to complete a form by providing your Social Security
            Number (“SSN”) along with other information on the form. There is a
            subset of third-party providers on the Elite Market Force network
            that may need your SSN to make a conditional offer. The second
            option does not require you to provide your SSN. There is a subset
            of third-party providers on the Elite Market Force network that
            prefer to take leads without an SSN. To use either service, you must
            provide the information requested on the form, agree to the full
            Electronic Consent, this Agreement, the Privacy Policy, and any
            additional terms that are included in the individual form you
            complete.
          </p>
          <p>
            Elite Market Force empowers consumers to comparison shop for
            mortgage products and is not licensed as a mortgage broker.
            <strong>
              Elite Market Force DOES NOT take formal mortgage applications as
              defined under applicable state laws, the Equal Credit Opportunity
              Act, the Truth In Lending Act (“TILA”), and the Real Estate
              Settlement Procedures Act (“RESPA”). Elite Market Force is not a
              lender or a creditor.
            </strong>
            Elite Market Force DOES NOT originate loans or make credit decisions
            in connection with loans, nor does Elite Market Force issue
            commitments or lock-in agreements. Elite Market Force's services are
            only administrative. Any form you submit is NOT an application for
            credit. Rather, it is an inquiry to be matched with third-party
            providers that may be able to present conditional offers. You will
            have to complete a formal application with the third-party provider
            before the third-party provider will extend an unconditional offer.
          </p>
          <p>
            If you close a loan with a third-party provider, you will be
            responsible for paying for any settlement services or closing costs
            associated with your loan (such as loan processing, underwriting,
            funding fees, appraisals, title insurance premiums, attorney and
            notary fees, etc.). The third-party provider, not Elite Market
            Force, and the settlement service providers will determine the
            amount of any such fees. Unless expressly stated in writing, nothing
            contained herein shall constitute an offer or promise for a loan
            commitment or interest rate lock-in agreement. Third-party providers
            may not offer all products as well as not offer products in all
            states. You might not be matched with any specific third-party
            providers making any specific offers.
          </p>
          <p>
            When you complete a form online, by clicking on any button
            indicating an acceptance, acknowledgement or agreement to terms, a
            continuance of processing or submission you understand that you are
            consenting, acknowledging and agreeing to the stated terms and
            conditions of that submission and that you are submitting an inquiry
            as to a product to which Elite Market Force will try to match you
            with third-party providers to whom your form data and personal
            information is transmitted. Elite Market Force will also try to find
            you matches for similar products for which you sought as well as
            alternative products that may be suitable for your financial needs.
            <br />
          </p>
          <h2>Questions? Concerns? Suggestions?</h2>
          <p>
            Please contact us at{" "}
            <a href="mailto:info@elitemarketforce.com">
              info@elitemarketforce.com
            </a>{" "}
            to report any violations of these Terms of Service or to pose any
            questions regarding these Terms of Service or the Services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
