import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ searching, max = 100 }) {
  const [progress, setProgress] = useState(1);
  const timer = useRef(null);

  useEffect(() => {
    timer.current = setInterval(() => {
      const randInt = Math.floor(Math.random() * 10);
      setProgress((prevProgress) =>
        prevProgress >= max ? max : prevProgress + randInt
      );
    }, 600);
    return () => {
      clearInterval(timer.current);
    };
  }, [max]);

  useEffect(() => {
    if (progress >= max) {
      clearInterval(timer.current);
      searching(false);
    } else {
      searching(true);
    }
  }, [progress, max, searching]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
