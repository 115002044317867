import React from "react";
import { Box, Typography } from "@mui/material";
import { AutocompleteController } from "./utils/AutocompleteController";
import { months, days, years } from "./utils/monthsDaysYears";

export const InputBirthDate = (props) => {
  return (
    <React.Fragment>
      <Typography>Birth Date:</Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ flexWrap: "wrap" }}
      >
        <AutocompleteController
          id="birthMonth"
          options={months}
          sx={{ width: 150, m: 0.5 }}
          name="birthMonth"
          label="Month"
          defaultValue={props.defaultValues.birthMonth}
        />
        <AutocompleteController
          id="birthDay"
          options={days}
          sx={{ width: 100, m: 0.5 }}
          name="birthDay"
          label="Day"
          defaultValue={props.defaultValues.birthDay}
        />
        <AutocompleteController
          disablePortal
          onOpen={() => {
            setTimeout(() => {
              const optionEl = document.querySelector(`[data-name="1980"]`);
              optionEl?.scrollIntoView();
            }, 1);
          }}
          id="birthYear"
          options={years}
          sx={{ width: 125, m: 0.5 }}
          name="birthYear"
          label="Year"
          defaultValue={props.defaultValues.birthYear}
          renderOption={(props, option) => {
            return (
              <li {...props} data-name={option.label}>
                {option.label}
              </li>
            );
          }}
        />
      </Box>
    </React.Fragment>
  );
};
