import { TextFieldController } from "./utils/TextFieldController";
import { Stack, Typography, Link } from "@mui/material";
import React from "react";
import { CheckboxController } from "./utils/CheckboxController";
import CpvLab from "./CpvLab";

export const LeadFormPii = (props) => {
  return (
    <React.Fragment>
      <Typography align="center" sx={{ p: 2 }}>
        Enter your name below to start your search for <b>unclaimed funds</b>,
        new <b>stimulus opportunities</b>, available <b>grant money</b> and
        other financial benefits you may qualify for:
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <TextFieldController
          name="fname"
          label="First name"
          defaultValue={props.defaultValues.fname}
        />
        <TextFieldController
          name="lname"
          label="Last name"
          defaultValue={props.defaultValues.lname}
        />
      </Stack>
      <Typography sx={{ p: 2, fontSize: 12, opacity: 0.7 }}>
        <label htmlFor="leadid_tcpa_disclosure">
          By checking the box below, I agree to the{" "}
          <Link href="/terms">terms</Link> and certify that I am at least 18
          years old. I also consent to your agents or{" "}
          <Link href="/terms">Marketing Partners</Link> contacting me by e-mail
          and with marketing calls or texts at the phone number I provide
          regardless of my status on State or Federal Do Not Call lists,
          including using an autodialer or artificial/prerecorded voice (msg &
          data rates apply).
        </label>
      </Typography>
      <Stack alignItems="center" justifyContent="center">
        <CheckboxController
          name="agree"
          label="I agree to the terms"
          id="leadid_tcpa_disclosure"
        />
      </Stack>
      <CpvLab campaignId="20" landingId="1" domain="c.usabenefitfinder.com" />
    </React.Fragment>
  );
};
