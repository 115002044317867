import React, { Component } from "react";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, Box } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Footer from "./components/Footer";
import AdvertisingDisclosure from "./components/AdvertisingDisclosure";
import ConsumerPrivacy from "./components/ConsumerPrivacy";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import DoNotSellMPI from "./components/DoNotSellMPI";
import logo from "./assets/img/logo-ubf.png";
import image from "./assets/img/bg-capitol-1280.jpg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import QuizApp from "./components/QuizApp";
import { getTrackerAction } from "./components/utils/tracker";
import PageNotFound from "./components/PageNotFound";
import GrantApp from "./components/GrantApp";
import UnclaimedApp from "./components/UnclaimedApp";
import LeadgenApp from "./components/LeadgenApp";

/*
Sentry.init({
  dsn: "https://9754bafee0ce4b3182d45b370c0705ea@o4504576006094848.ingest.sentry.io/4504576013697024",
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect
      ),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
*/

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#bbcaeb",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 25,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: "white",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "forestgreen",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          backgroundColor: "white",
        },
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      pii: {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        gender: "",
        dob: "",
        homeowner: 0,
        unemployed: 0,
      },
      interests: {
        edu: 0,
        credit: 0,
        debt: 0,
        loan: 0,
        refi: 0,
        bizopp: 0,
        grants: 0,
        disability: 0,
        unclaimed: 0,
        health: 0,
        car: 0,
        sweeps: 0,
      },
    };
  }

  /*
  componentDidMount() {
    // redirect back button to homepage
    const currentUrl = window.location.href;
    window.history.replaceState(null, "", "#nb");
    window.history.pushState(null, "", currentUrl);
    window.addEventListener("popstate", function () {
      if (this.window.location.hash === "#nb") {
        window.history.replaceState(null, "", currentUrl);
        setTimeout(function () {
          window.location.href = "/";
        }, 0);
      }
    });
  }
*/

  render() {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <div className="App-header">
              <Box
                component="img"
                src={logo}
                alt="USA Benefit Finder"
                sx={{ width: "280px" }}
              />
            </div>
            <div className="shadowContainer">
              <Routes>
                <Route path="/start/*" element={<LeadgenApp />} />
                <Route path="/gr/*" element={<GrantApp />} />
                <Route
                  path="/uc/*"
                  element={<UnclaimedApp campaignId="18" landingId="1" />}
                />
                <Route
                  path="/ucg/*"
                  element={<UnclaimedApp campaignId="31" landingId="1" />}
                />
                {/* <Route path="/quiz/1/:page?" element={<QuizApp />} /> */}
                {/* <Route path="/quiz">
                <div>
                  {this.state.result ? this.renderResult() : this.renderQuiz()}
                  <FunnelFlux type="view" p="1QV3vBCZ0741" />
                </div>
              </Route> */}
                <Route
                  path="/quiz"
                  element={() => {
                    window.location.href = getTrackerAction(1);
                    return null;
                  }}
                />
                {/* <Route path="/ttq/:page/*" element={<QuizApp />} /> */}
                <Route
                  path="/advertising-disclosure"
                  element={<AdvertisingDisclosure />}
                />
                <Route path="/consumer-privacy" element={<ConsumerPrivacy />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route
                  path="/do-not-sell-my-personal-information"
                  element={<DoNotSellMPI />}
                />
                <Route
                  path="/contact"
                  element={() => {
                    window.location.href = getTrackerAction(1);
                    return null;
                  }}
                />
                <Route
                  path="/"
                  element={<UnclaimedApp campaignId="18" landingId="3" />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
