import React from "react";
import { useEffect } from "react";

const CpvLab = ({ campaignId, landingId, domain }) => {
  const domainUrl = `https://${domain}/`;

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = 'var clpconfig = { "engsec" : "4",  "clcsr" : "1", };';

    const script2 = document.createElement("script");
    script2.src = `${domainUrl}landing.js`;
    script2.async = false;
    if (campaignId && landingId) {
      script2.onload = () => {
        const script3 = document.createElement("script");
        script3.innerHTML = `checkdirect(${campaignId},${landingId})`;
        document.body.appendChild(script3);
      };
    }

    async function addElements() {
      await document.body.appendChild(script);
      await document.body.appendChild(script2);
    }

    addElements();
  }, []);

  return (
    <React.Fragment>
      <input type="hidden" id="hidLocation" value={domainUrl} />
    </React.Fragment>
  );
};

export default CpvLab;
