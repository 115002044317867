import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const InputGender = ({ ...rest }) => {
  const { sx } = rest;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={rest.defaultValue}
      render={({ field, fieldState: { invalid, error } }) => (
        <FormControl sx={sx}>
          <FormLabel
            id="gender-label"
            sx={{ textAlign: "center", color: invalid ? "#d32f2f" : "black" }}
          >
            Gender:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender-label"
            name={rest.name}
            label={rest.label}
            {...field}
          >
            <FormControlLabel value="F" control={<Radio />} label="Female" />
            <FormControlLabel value="M" control={<Radio />} label="Male" />
            <FormControlLabel value="O" control={<Radio />} label="Other" />
          </RadioGroup>
          {invalid && (
            <FormHelperText error id="gender-error">
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {...rest}
    />
  );
};
