import React, { useState } from "react";
import {
  Typography,
  Card,
  CardActionArea,
  CardHeader,
  Avatar,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useFormContext } from "react-hook-form";
import resultsOffers from "../api/resultsOffers";
import { statesUs } from "./utils/StatesUS";
import { months } from "./utils/monthsDaysYears";

function getObjKey(obj, value) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function getAge(year, month, day) {
  var today = new Date();
  var birthDate = new Date(year, month - 1, day); //month-1 since the month in the date object is 0 indexed
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function capitalize(str) {
  if (typeof str === "string") {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return "";
  }
}

export const LeadFormResults = (props) => {
  const { getValues } = useFormContext();
  const fname = capitalize(getValues("fname"));
  const lname = capitalize(getValues("lname"));
  const birthYear = getValues("birthYear");
  const birthMonth = getValues("birthMonth");
  const birthDay = getValues("birthDay");
  const usersState =
    getValues("state") && getValues("state").length === 2
      ? getValues("state")
      : getObjKey(statesUs, getValues("state"));

  const [displaySteps, setDisplaySteps] = useState(2);
  const showNextSteps = () => {
    setDisplaySteps(displaySteps + 2);
  };

  const filteredOffers = resultsOffers.filter((offer) => {
    if (offer.enabled) {
      if (
        offer.niche === "unclaimed" ||
        offer.niche === "grants" ||
        offer.niche === "sweeps" ||
        offer.niche === "survey" ||
        offer.niche === "stimulus" ||
        offer.niche === "senior" ||
        offer.niche === "tort" ||
        offer.niche === "loan" ||
        getValues(offer.niche) === "Y"
      ) {
        var isAllowed = true;

        // check for any offer restrictions
        if (typeof offer.restrictions !== "undefined") {
          isAllowed = false;
          var allowCount = 0;
          const d = new Date();

          for (const restrict in offer.restrictions) {
            const restrictionValue = offer.restrictions[restrict];

            // check if offer has geo state restrictions
            if (restrict === "state") {
              // check if user's state is allowed on the offer
              const isStateAllowed = restrictionValue.some(
                (st) => usersState === st
              );
              if (isStateAllowed) allowCount++;
            }

            // check if offer has day parting restrictions
            if (restrict === "dayPart") {
              const weekdays = [
                "Sun",
                "Mon",
                "Tue",
                "Wed",
                "Thu",
                "Fri",
                "Sat",
              ];

              const isDayAllowed = restrictionValue.some(
                (day) => weekdays[d.getDay()] === day
              );
              if (isDayAllowed) allowCount++;
            }

            // check if offer has hour parting restrictions
            if (restrict === "hourPart") {
              const estTime = d.toLocaleString("en-US", {
                timeZone: "America/New_York",
              });
              const estHours = new Date(estTime).getHours();

              const isHourAllowed = restrictionValue.some(
                (hour) => estHours === hour
              );
              if (isHourAllowed) allowCount++;
            }

            // check if offer has minimum age restriction
            if (restrict === "minAge") {
              if (
                birthMonth &&
                getAge(
                  birthYear,
                  months
                    .find(({ label }) => label === birthMonth)
                    .id.toString(),
                  birthDay
                ) > offer.restrictions.minAge
              )
                allowCount++;
            }

            // check if offer has maximum age restriction
            if (restrict === "maxAge") {
              if (
                birthMonth &&
                getAge(
                  birthYear,
                  months
                    .find(({ label }) => label === birthMonth)
                    .id.toString(),
                  birthDay
                ) < offer.restrictions.maxAge
              )
                allowCount++;
            }
          }

          if (allowCount === Object.keys(offer.restrictions).length)
            isAllowed = true;
        }

        return isAllowed;
      }
    }
    return false;
  });

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Typography align="center" variant="h6" sx={{ p: 1 }}>
          {filteredOffers.length}{" "}
          {filteredOffers.length === 1 ? "Resource" : "Resources"} found for:{" "}
        </Typography>
        <Box
          component="span"
          sx={{
            backgroundColor: "rgb(255, 255, 230)",
            p: 0.5,
            fontSize: 16,
            fontWeight: 400,
            border: "1px solid",
            borderColor: "rgba(0, 0, 0, 0.37)",
            whiteSpace: "nowrap", // Text will never wrap to next line
            overflow: "hidden", // Make sure overflow is hidden
            textOverflow: "ellipsis", // Add ellipsis when text overflows
            display: "block", // Make sure this is a block element
            maxWidth: "100%", // Define a max width
          }}
        >
          {fname} {lname}
        </Box>
      </Box>
      <Typography
        align="center"
        sx={{ p: 1, maxWidth: { xs: "98%", sm: "90%" }, m: "auto" }}
      >
        <b>Congrats {fname}!</b> We found {filteredOffers.length}{" "}
        {filteredOffers.length === 1 ? "resource" : "resources"} that you may
        qualify for based on the information you provided.{" "}
        <b>Follow the steps below to maximize your results!</b>
      </Typography>
      <Typography
        align="center"
        sx={{ p: 1, maxWidth: { xs: "98%", sm: "80%" }, m: "auto" }}
      >
        <b>NOTE:</b> Each resource opens in a new browser tab so you can return
        to this page.
      </Typography>
      {filteredOffers.slice(0, displaySteps).map((offer, i) => {
        const stepNumber = i + 1;

        return (
          <React.Fragment key={`offer-${i}`}>
            <Typography align="center">
              <b>STEP {stepNumber}:</b>
            </Typography>
            <Card
              key={i}
              sx={{
                width: { xs: "95%", sm: "80%" },
                mx: "auto",
                my: 1,
                alignItems: "center",
                borderRadius: "30px",
              }}
            >
              <CardActionArea
                href={offer.url}
                target="_blank"
                onClick={() => {
                  setTimeout(console.log("click"), 2000);
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      alt={offer.title}
                      src={offer.img}
                      variant="square"
                      sx={{ width: 50, height: 50 }}
                    />
                  }
                  action={
                    <IconButton sx={{ mt: 1, color: "forestgreen" }}>
                      <DoubleArrowIcon />
                    </IconButton>
                  }
                  titleTypographyProps={{
                    fontSize: 18,
                  }}
                  title={offer.title}
                  subheader={offer.desc}
                />
              </CardActionArea>
            </Card>
          </React.Fragment>
        );
      })}
      {displaySteps < filteredOffers.length && (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Button
            type="button"
            onClick={showNextSteps}
            startIcon={<KeyboardDoubleArrowDownIcon />}
            endIcon={<KeyboardDoubleArrowDownIcon />}
            sx={{
              fontSize: "18px",
              bgcolor: "green", // setting background color
              color: "white",
              "&:hover": {
                backgroundColor: "rgb(230, 255, 230)", // setting hover background color
                color: "rgba(0, 0, 0, 0.87)", // setting text color
              },
            }}
          >
            Show More Resources
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};
