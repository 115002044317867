import React, { useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import LinearWithValueLabel from "./utils/LinearWithValueLabel";
import { InputBirthDate } from "./InputBirthDate";
import { InputGender } from "./InputGender";
import { TextFieldController } from "./utils/TextFieldController";
import { PhoneFieldController } from "./utils/PhoneFieldController";

export const LeadFormPii2 = (props) => {
  const [searching, setSearching] = useState(true);
  const { getValues } = useFormContext();
  const fname = getValues("fname");
  const lname = getValues("lname");

  const onSearchingChanged = (status) => {
    setSearching(status);
    props.onChangeSearching(status);
  };

  return (
    <React.Fragment>
      <Typography align="center" sx={{ p: 1 }}>
        Starting resource search for:{" "}
        <b>
          {fname} {lname}
        </b>
      </Typography>
      <Stack alignItems="center" justifyContent="center">
        <Typography sx={{ p: 1 }}>Searching Resources...</Typography>
        {searching && (
          <Box sx={{ width: "60%" }}>
            <LinearWithValueLabel searching={onSearchingChanged} max={50} />
          </Box>
        )}
        {!searching && (
          <React.Fragment>
            <Typography
              align="center"
              color="error"
              sx={{ p: 1, border: 1, borderRadius: "6px" }}
            >
              Too many results found, additional info is required.
            </Typography>
            <Typography align="center" sx={{ p: 2 }}>
              <b>To view your results</b>, please provide the following
              additional info:
            </Typography>
            <InputBirthDate
              sx={{ p: 1 }}
              name="birthDate"
              label="Birth Date"
              defaultValues={props.defaultValues}
            />
            <InputGender
              sx={{ p: 1 }}
              name="gender"
              label="Gender"
              defaultValue={props.defaultValues.gender}
            />
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              justifyContent="center"
              sx={{ m: 1 }}
            >
              <TextFieldController
                name="email"
                label="E-mail"
                defaultValue={props.defaultValues.email}
              />
              <PhoneFieldController
                name="phone"
                label="Phone"
                defaultValue={props.defaultValues.phone}
              />
            </Stack>
          </React.Fragment>
        )}
      </Stack>
      {/* <Typography>Debug: {phone}</Typography> */}
    </React.Fragment>
  );
};
