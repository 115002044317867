import React from "react";
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bFold">
      <div className="App-Footer">
        <ul className="_appLinks_e6ad3">
          <li>
            <a href="/terms">Terms</a>
          </li>
          <li>
            <a href="/privacy">Privacy</a>
          </li>
          <li>
            <a href="/do-not-sell-my-personal-information">
              Do Not Sell My Personal Information
            </a>
          </li>
          <li>
            <a href="/consumer-privacy">Contact</a>
          </li>
        </ul>
        <ul className="_appLinks_e6ad3">
          <li>© 2022 USABenefitFinder.com</li>
          <li>All Rights Reserved.</li>
        </ul>
        <p>Not affiliated with any state or government agency.</p>
      </div>
    </div>
  );
};

export default Footer;
