import { useEffect } from "react";

const GtagEvent = ({ type, sendTo }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `gtag('${type}', 'conversion', {'send_to': '${sendTo}'});`;

    document.body.appendChild(script);
  }, [type, sendTo]);

  return null;
};

export default GtagEvent;
