import { TextField, Autocomplete } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const AutocompleteController = ({ ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      defaultValue={rest.defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => {
        return (
          <Autocomplete
            sx={rest.sx}
            options={rest.options}
            onChange={(event, values) => onChange(values.label)}
            value={
              typeof value === "string"
                ? rest.options.find((o) => o.label === value)
                : value || null
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                error={invalid}
                helperText={invalid ? error.message : ""}
                label={rest.label}
              />
            )}
            {...rest}
          />
        );
      }}
      {...rest}
    />
  );
};
