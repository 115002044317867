export const months = [
  { label: "January", id: 1 },
  { label: "February", id: 2 },
  { label: "March", id: 3 },
  { label: "April", id: 4 },
  { label: "May", id: 5 },
  { label: "June", id: 6 },
  { label: "July", id: 7 },
  { label: "August", id: 8 },
  { label: "September", id: 9 },
  { label: "October", id: 10 },
  { label: "November", id: 11 },
  { label: "December", id: 12 },
];

export const days = [
  { label: "1", id: 1 },
  { label: "2", id: 2 },
  { label: "3", id: 3 },
  { label: "4", id: 4 },
  { label: "5", id: 5 },
  { label: "6", id: 6 },
  { label: "7", id: 7 },
  { label: "8", id: 8 },
  { label: "9", id: 9 },
  { label: "10", id: 10 },
  { label: "11", id: 11 },
  { label: "12", id: 12 },
  { label: "13", id: 13 },
  { label: "14", id: 14 },
  { label: "15", id: 15 },
  { label: "16", id: 16 },
  { label: "17", id: 17 },
  { label: "18", id: 18 },
  { label: "19", id: 19 },
  { label: "20", id: 20 },
  { label: "21", id: 21 },
  { label: "22", id: 22 },
  { label: "23", id: 23 },
  { label: "24", id: 24 },
  { label: "25", id: 25 },
  { label: "26", id: 26 },
  { label: "27", id: 27 },
  { label: "28", id: 28 },
  { label: "29", id: 29 },
  { label: "30", id: 30 },
  { label: "31", id: 31 },
];

export const years = [
  { label: "1900", id: 1900 },
  { label: "1901", id: 1901 },
  { label: "1902", id: 1902 },
  { label: "1903", id: 1903 },
  { label: "1904", id: 1904 },
  { label: "1905", id: 1905 },
  { label: "1906", id: 1906 },
  { label: "1907", id: 1907 },
  { label: "1908", id: 1908 },
  { label: "1909", id: 1909 },
  { label: "1910", id: 1910 },
  { label: "1911", id: 1911 },
  { label: "1912", id: 1912 },
  { label: "1913", id: 1913 },
  { label: "1914", id: 1914 },
  { label: "1915", id: 1915 },
  { label: "1916", id: 1916 },
  { label: "1917", id: 1917 },
  { label: "1918", id: 1918 },
  { label: "1919", id: 1919 },
  { label: "1920", id: 1920 },
  { label: "1921", id: 1921 },
  { label: "1922", id: 1922 },
  { label: "1923", id: 1923 },
  { label: "1924", id: 1924 },
  { label: "1925", id: 1925 },
  { label: "1926", id: 1926 },
  { label: "1927", id: 1927 },
  { label: "1928", id: 1928 },
  { label: "1929", id: 1929 },
  { label: "1930", id: 1930 },
  { label: "1931", id: 1931 },
  { label: "1932", id: 1932 },
  { label: "1933", id: 1933 },
  { label: "1934", id: 1934 },
  { label: "1935", id: 1935 },
  { label: "1936", id: 1936 },
  { label: "1937", id: 1937 },
  { label: "1938", id: 1938 },
  { label: "1939", id: 1939 },
  { label: "1940", id: 1940 },
  { label: "1941", id: 1941 },
  { label: "1942", id: 1942 },
  { label: "1943", id: 1943 },
  { label: "1944", id: 1944 },
  { label: "1945", id: 1945 },
  { label: "1946", id: 1946 },
  { label: "1947", id: 1947 },
  { label: "1948", id: 1948 },
  { label: "1949", id: 1949 },
  { label: "1950", id: 1950 },
  { label: "1951", id: 1951 },
  { label: "1952", id: 1952 },
  { label: "1953", id: 1953 },
  { label: "1954", id: 1954 },
  { label: "1955", id: 1955 },
  { label: "1956", id: 1956 },
  { label: "1957", id: 1957 },
  { label: "1958", id: 1958 },
  { label: "1959", id: 1959 },
  { label: "1960", id: 1960 },
  { label: "1961", id: 1961 },
  { label: "1962", id: 1962 },
  { label: "1963", id: 1963 },
  { label: "1964", id: 1964 },
  { label: "1965", id: 1965 },
  { label: "1966", id: 1966 },
  { label: "1967", id: 1967 },
  { label: "1968", id: 1968 },
  { label: "1969", id: 1969 },
  { label: "1970", id: 1970 },
  { label: "1971", id: 1971 },
  { label: "1972", id: 1972 },
  { label: "1973", id: 1973 },
  { label: "1974", id: 1974 },
  { label: "1975", id: 1975 },
  { label: "1976", id: 1976 },
  { label: "1977", id: 1977 },
  { label: "1978", id: 1978 },
  { label: "1979", id: 1979 },
  { label: "1980", id: 1980 },
  { label: "1981", id: 1981 },
  { label: "1982", id: 1982 },
  { label: "1983", id: 1983 },
  { label: "1984", id: 1984 },
  { label: "1985", id: 1985 },
  { label: "1986", id: 1986 },
  { label: "1987", id: 1987 },
  { label: "1988", id: 1988 },
  { label: "1989", id: 1989 },
  { label: "1990", id: 1990 },
  { label: "1991", id: 1991 },
  { label: "1992", id: 1992 },
  { label: "1993", id: 1993 },
  { label: "1994", id: 1994 },
  { label: "1995", id: 1995 },
  { label: "1996", id: 1996 },
  { label: "1997", id: 1997 },
  { label: "1998", id: 1998 },
  { label: "1999", id: 1999 },
  { label: "2000", id: 2000 },
  { label: "2001", id: 2001 },
  { label: "2002", id: 2002 },
  { label: "2003", id: 2003 },
  { label: "2004", id: 2004 },
];
