import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const CheckboxController = ({ ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={rest.defaultValue}
      render={({ field, fieldState: { invalid, error } }) => {
        return (
          <React.Fragment>
            <FormControlLabel
              label={rest.label}
              control={
                <Checkbox
                  {...field}
                  checked={field.value ? field.value : false}
                  inputProps={{ id: rest.id }}
                />
              }
            />
            {error && (
              <FormHelperText error>
                {error ? error.message : ""}
              </FormHelperText>
            )}
          </React.Fragment>
        );
      }}
      {...rest}
    />
  );
};
