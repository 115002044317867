import React from "react";
import {
  OutlinedInput,
  FormHelperText,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TextMaskPhone } from "./TextMaskPhone";

export const PhoneFieldController = ({ ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={rest.defaultValue}
      render={({ field, fieldState: { invalid, error } }) => (
        <FormControl>
          <InputLabel htmlFor="phone">Phone</InputLabel>
          <OutlinedInput
            label={rest.label}
            {...field}
            error={invalid}
            name="phone"
            inputComponent={TextMaskPhone}
          />
          {invalid && (
            <FormHelperText error id="phone-error">
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {...rest}
    />
  );
};
