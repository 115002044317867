import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { yupResolver } from "@hookform/resolvers/yup";
import { LeadFormPii } from "./LeadFormPii";
import { LeadFormPii2 } from "./LeadFormPii2";
import { LeadFormPii3 } from "./LeadFormPii3";
import { LeadFormResults } from "./LeadFormResults";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { months } from "./utils/monthsDaysYears";
import { statesUs } from "./utils/StatesUS";
import Mautic from "./utils/Mautic";
import Jornaya from "./utils/Jornaya";

const steps = ["Start", "Search", "Results"];
const defaultValues = {
  fname: "",
  lname: "",
  agree: false,
  email: "",
  phone: "",
  gender: "",
  birthMonth: null,
  birthDay: null,
  birthYear: null,
  address: "",
  zip: "",
  city: "",
  state: "",
  homeowner: "",
  edu: "",
  unemployed: "",
  disability: "",
  debt: "",
};

const LeadgenApp = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [searching, setSearching] = useState(false);
  const [trackerClicks, setTrackerClicks] = useState(0);
  const trackerClicksRef = useRef(null);
  trackerClicksRef.current = false;
  const urlParams = useParams()["*"];
  const location = useLocation();
  const navigate = useNavigate();
  const urlStep = Number(urlParams);

  // force activeStep if URL param exists
  if (urlStep !== "NaN" && urlStep !== 0 && urlStep - 1 !== activeStep) {
    setActiveStep(urlStep - 1);
  }

  const onChangeSearching = (newState) => {
    setSearching(newState);
  };

  const birthDate = (year, month, day) => {
    return year && month && day
      ? year +
          "-" +
          months
            .find(({ label }) => label === month)
            .id.toString()
            .padStart(2, "0") +
          "-" +
          day.padStart(2, "0")
      : "";
  };

  const validationSchema = useMemo(
    () => [
      //validation for step1
      yup.object({
        fname: yup.string().required("Required"),
        lname: yup.string().required("Required"),
        agree: yup
          .boolean()
          .test("agree", "Error: You must agree to the terms", (val) => {
            return val;
          }),
      }),
      //validation for step2
      yup.object({
        gender: yup.string().required("Required"),
        birthMonth: yup.string().required("Required"),
        birthDay: yup.string().required("Required"),
        birthYear: yup.string().required("Required"),
        email: yup
          .string()
          .email("Must be a valid email")
          .max(255)
          .required("Required"),
        phone: yup
          .string()
          .required("Required")
          .matches(/^[2-9][0-9]{9}$/, "Phone number is not valid"),
      }),
      //validation for step3
      yup.object({
        address: yup.string().required("Required"),
        zip: yup
          .string()
          .required("Required")
          .length(5, "Zip Code is not valid"),
        homeowner: yup.string().required("Required"),
        edu: yup.string().required("Required"),
        unemployed: yup.string().required("Required"),
        disability: yup.string().required("Required"),
        debt: yup.string().required("Required"),
      }),
    ],
    []
  );

  const currentValidationSchema = validationSchema[activeStep];
  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    watch,
    getValues,
    formState: { isSubmitting },
  } = methods;
  useFormPersist("storageKey", {
    watch,
    setValue,
    storage: window.localStorage,
  });

  // setup tags
  const tags = [];
  getValues("homeowner") === "Y" && tags.push("homeowner");
  getValues("edu") === "Y" && tags.push("edu");
  getValues("unemployed") === "Y" && tags.push("unemployed");
  getValues("disability") === "Y" && tags.push("disability");
  getValues("debt") === "Y" && tags.push("debt");

  useEffect(() => {
    setTrackerClicks(JSON.parse(window.localStorage.getItem("trackerClicks")));
    trackerClicksRef.current = true;

    const validateFormData = async (schema) => {
      try {
        await schema.validate(getValues());
        return true;
      } catch (error) {
        // console.log("prevValidate error", error);
        return false;
      }
    };

    // if it's not the first step, verify previous steps have valid data
    if (activeStep) {
      validateFormData(validationSchema[0]).then((result) => {
        // console.log("step 1 validate", result);
        if (!result) {
          navigate("/" + location.pathname.split("/")[1] + "/1"); // navigate to step 1
        } else {
          if (activeStep > 1) {
            validateFormData(validationSchema[1]).then((result) => {
              // console.log("step 2 validate", result);
              if (!result) {
                navigate("/" + location.pathname.split("/")[1] + "/2"); // navigate to step 2
              } else {
                if (activeStep > 2) {
                  validateFormData(validationSchema[2]).then((result) => {
                    // console.log("step 3 validate", result);
                    if (!result) {
                      navigate("/" + location.pathname.split("/")[1] + "/3"); // navigate to step 3
                    }
                  });
                }
              }
            });
          }
        }
      });
    }
  }, [activeStep, getValues, location.pathname, navigate, validationSchema]);

  useEffect(() => {
    if (!trackerClicksRef.current)
      window.localStorage.setItem("trackerClicks", trackerClicks);
  }, [trackerClicks]);

  const onSubmit = (data) => {
    // alert(JSON.stringify(data));

    return new Promise((resolve) => {
      // submit lead to Mautic if step 2+
      if (activeStep) {
        // convert data object to Mautic fields
        const mauticData = new FormData();
        mauticData.append("mauticform[formId]", 1);
        mauticData.append("mauticform[messenger]", 1);
        mauticData.append(
          "mauticform[universal_leadid]",
          document.getElementById("leadid_token").value
        );
        const strFname = getValues("fname");
        const strLname = getValues("lname");
        mauticData.append(
          "mauticform[fname]",
          strFname.charAt(0).toUpperCase() + strFname.slice(1)
        );
        mauticData.append(
          "mauticform[lname]",
          strLname.charAt(0).toUpperCase() + strLname.slice(1)
        );
        mauticData.append(
          "mauticform[email_address]",
          getValues("email").toLowerCase()
        );
        mauticData.append("mauticform[phone]", getValues("phone"));
        mauticData.append("mauticform[birth_year]", getValues("birthYear"));
        mauticData.append(
          "mauticform[birth_month]",
          months
            .find(({ label }) => label === getValues("birthMonth"))
            .id.toString()
            .padStart(2, "0")
        );
        mauticData.append(
          "mauticform[birth_day]",
          getValues("birthDay").padStart(2, "0")
        );
        mauticData.append(
          "mauticform[birth_date]",
          birthDate(
            getValues("birthYear"),
            getValues("birthMonth"),
            getValues("birthDay")
          )
        );
        mauticData.append("mauticform[gender]", getValues("gender"));
        getValues("address") &&
          mauticData.append("mauticform[address]", getValues("address"));
        getValues("city") &&
          mauticData.append("mauticform[city]", getValues("city"));
        const stateStr =
          getValues("state") && getValues("state").length === 2
            ? statesUs[getValues("state")]
            : getValues("state");
        getValues("state") && mauticData.append("mauticform[state]", stateStr);
        getValues("zip") &&
          mauticData.append("mauticform[zip]", getValues("zip"));

        // send ajax request to Mautic
        axios
          .post("https://m.usabenefitfinder.com/form/submit", mauticData)
          .finally((response) => {
            handleNext().then(resolve());
          })
          .catch((error) => console.log(error));
      } else {
        handleNext().then(resolve());
      }
    });
  };

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      // update CpvLab tokens
      const searchParams = new URLSearchParams();
      getValues("fname") && searchParams.append("token5", getValues("fname"));
      getValues("lname") && searchParams.append("token6", getValues("lname"));
      getValues("birthYear") &&
        getValues("birthMonth") &&
        getValues("birthDay") &&
        searchParams.append(
          "token7",
          birthDate(
            getValues("birthYear"),
            getValues("birthMonth"),
            getValues("birthDay")
          )
        );
      getValues("gender") && searchParams.append("token8", getValues("gender"));
      getValues("email") && searchParams.append("token9", getValues("email"));
      getValues("phone") && searchParams.append("token10", getValues("phone"));
      getValues("address") &&
        searchParams.append("token11", getValues("address"));
      getValues("zip") && searchParams.append("token12", getValues("zip"));
      getValues("city") && searchParams.append("token13", getValues("city"));
      getValues("state") && searchParams.append("token14", getValues("state"));
      getValues("birthMonth") &&
        searchParams.append(
          "token15",
          months
            .find(({ label }) => label === getValues("birthMonth"))
            .id.toString()
            .padStart(2, "0")
        );
      getValues("birthDay") &&
        searchParams.append("token16", getValues("birthDay").padStart(2, "0"));
      getValues("birthYear") &&
        searchParams.append("token17", getValues("birthYear"));

      axios
        .get(
          "https://c.usabenefitfinder.com/tokens.php?" + searchParams.toString()
        )
        .finally(function () {
          if (trackerClicks > activeStep) {
            navigate(
              "/" + location.pathname.split("/")[1] + "/" + (activeStep + 2)
            );
          } else {
            setTrackerClicks((prevTrackerClicks) => prevTrackerClicks + 1);
            window.location.href = "https://c.usabenefitfinder.com/base2.php";
            // navigate("/track-base2");
          }
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    navigate("/" + location.pathname.split("/")[1] + "/" + activeStep);
  };

  const getStepContent = (control) => {
    switch (activeStep) {
      case 0:
        return <LeadFormPii control={control} defaultValues={defaultValues} />;
      case 1:
        return (
          <LeadFormPii2
            control={control}
            defaultValues={defaultValues}
            onChangeSearching={onChangeSearching}
          />
        );
      case 2:
        return (
          <LeadFormPii3
            control={control}
            defaultValues={defaultValues}
            onChangeSearching={onChangeSearching}
          />
        );
      case 3:
        return <LeadFormResults control={control} />;
      default:
        return null;
    }
  };

  return (
    <Box className="App-content">
      <Typography variant="h4" align="center" sx={{ fontWeight: 500, mb: 1 }}>
        Search For Resources:
      </Typography>
      <Box
        sx={{
          p: 1,
          borderRadius: 3,
          backgroundColor: "rgba(245, 245, 245, 0.9)",
        }}
      >
        <Stepper activeStep={activeStep} sx={{ width: "90%", mx: "auto" }}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <FormProvider {...methods}>
          <form>
            <input id="leadid_token" name="universal_leadid" type="hidden" />
            <Box my={2}>{getStepContent(control)}</Box>
            <Box display="flex" justifyContent="center" style={{ padding: 20 }}>
              {!activeStep ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={handleNext}
                  endIcon={<DoubleArrowIcon />}
                >
                  START SEARCH
                </Button>
              ) : (
                <React.Fragment>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  {!searching &&
                    (activeStep === steps.length - 1 ? (
                      <LoadingButton
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        loadingPosition="end"
                        variant="contained"
                        color="success"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<DoubleArrowIcon />}
                      >
                        VIEW RESOURCES
                      </LoadingButton>
                    ) : (
                      <React.Fragment>
                        {!searching &&
                          (activeStep === steps.length ? null : (
                            <LoadingButton
                              disabled={isSubmitting}
                              loading={isSubmitting}
                              loadingPosition="end"
                              variant="contained"
                              color="success"
                              onClick={handleSubmit(onSubmit)}
                              endIcon={<DoubleArrowIcon />}
                            >
                              SEARCH
                            </LoadingButton>
                          ))}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              )}
            </Box>
          </form>
        </FormProvider>
      </Box>
      {activeStep === 3 && tags.length ? (
        <Mautic email={getValues("email")} tags={tags} />
      ) : (
        <React.Fragment>{activeStep !== 3 && <Mautic />}</React.Fragment>
      )}
      <Jornaya />
    </Box>
  );
};

export default LeadgenApp;
