import React from "react";

const ConsumerPrivacy = () => {
  return (
    <div className="App-content">
      <div class="_header_4a3ac">
        <h1>Consumer Privacy</h1>
        <div class="_meta_4a3ac">Last Updated: December 8, 2022</div>
      </div>
      <div class="_body_4a3ac">
        <div class="_richText_7afec">
          <h2>
            What does USABenefitFinder.com do with your personal information?
          </h2>
          <h3>Why?</h3>
          <p>
            Financial companies choose how they share your personal information.
            Federal law gives consumers the right to limit some but not all
            sharing. Federal law also requires us to tell you how we collect,
            share, and protect your personal information. Please read this
            notice carefully to understand what we do.
          </p>
          <h3>What?</h3>
          <p>
            The types of personal information we collect and share depend on the
            product or service you have with us. This information can include:
          </p>
          <ul>
            <li>Your contact details and demographic information</li>
            <li>Social Security number and account transactions</li>
            <li>Credit history and credit scores</li>
            <li>Transaction history and credit products rates and payments</li>
          </ul>
          <h3>How?</h3>
          <p>
            All financial companies need to share customers' personal
            information to run their everyday business. In the section below, we
            list the reasons financial companies can share their customers'
            personal information, the reasons Elite Market Force, LLC chooses to
            share, and whether you can limit this sharing.
          </p>
          <div class="responsiveTable">
            <table>
              <thead>
                <tr>
                  <th width="50%">
                    Reasons we can share your personal information
                  </th>
                  <th width="25%">Do we share?</th>
                  <th width="25%">Can you limit sharing?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>For our everyday business purposes</strong> - such
                    as to process your transactions, maintain your account(s),
                    respond to court orders and legal investigations, or report
                    to credit bureaus
                  </td>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <strong>For our marketing purposes</strong> - to offer our
                    products and services to you
                  </td>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      For joint marketing with other financial companies
                    </strong>
                  </td>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      For our affiliates' everyday business purposes
                    </strong>{" "}
                    - information about your transactions and experiences
                  </td>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      For our affiliates' everyday business purposes
                    </strong>{" "}
                    - information about your creditworthiness
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <strong>For our affiliates to market to you</strong>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <strong>For nonaffiliates to market to you</strong>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2>To limit our sharing</h2>
          <ul>
            <li>
              Visit us online:{" "}
              <a href="https://elitemarketforce.com/contact-us/">
                www.elitemarketforce.com/contact-us/
              </a>
            </li>
          </ul>
          <p>
            Please note: if you are a <em>new</em> customer, we begin sharing
            your information when we provide this notice. When you are{" "}
            <em>no longer</em> a customer, we continue to share your information
            as described in this notice. However, you may contact us at any time
            to limit our sharing.
          </p>
          <h2>Questions?</h2>
          <p>Call us at (307) 459-5078</p>
          <h2>Who we are</h2>
          <p>
            <strong>Who is providing this notice:</strong> Elite Market Force,
            LLC, the owner and operator of USABenefitFinder.com, is providing
            the privacy policy.
          </p>
          <h2>What we do</h2>
          <p>
            <strong>
              How does Elite Market Force, LLC protect my personal information?
            </strong>
          </p>
          <p>
            To protect your personal information from unauthorized access and
            use, we use security measures that comply with federal law. These
            measures include computer safeguards and secured files and
            buildings.
          </p>
          <p>
            We use encryption in the transmittal of your sensitive personal
            information between your systems and ours and we use firewalls.
          </p>
          <p>
            <strong>
              How does Elite Market Force, LLC collect my personal information?
            </strong>
          </p>
          <p>We collect your personal information, for example, when you</p>
          <ul>
            <li>Open an account </li>
            <li>Access our website</li>
            <li>
              Search for information about a financial product or service, such
              as a loan
            </li>
          </ul>
          <p>
            We also collect your personal information from others, such as
            credit bureaus, affiliates, or other companies.
          </p>
          <p>
            <strong>Why can't I limit all sharing?</strong>
          </p>
          <p>Federal law gives you the right to limit only</p>
          <ul>
            <li>
              sharing for affiliates' everyday business purposes—information
              about your creditworthiness
            </li>
            <li>affiliates from using your information to market to you</li>
            <li>sharing for nonaffiliates to market to you</li>
          </ul>
          <p>
            State laws and individual companies may give you additional rights
            to limit sharing. See below for more on your rights under state law.
          </p>
          <h2>Definitions</h2>
          <p>
            <strong>Affiliates:</strong> Companies related by common ownership
            or control. They can be financial and nonfinancial companies.
          </p>
          <p>
            <strong>Nonaffiliates:</strong> Companies not related by common
            ownership or control. They can be financial and nonfinancial
            companies. Nonaffiliates we share with may include loan brokers,
            lenders, insurance companies and direct marketing companies.
          </p>
          <p>
            <strong>Joint marketing:</strong> A formal agreement between
            nonaffiliated financial companies that together market financial
            products or services to you. Our joint marketing partners may
            include lenders and other third parties that provide services to our
            consumers in our network.
          </p>
          <h2>Other important information</h2>
          <p>
            <strong>NV:</strong> If you are consumer with a Nevada mailing
            address, and you prefer not to receive marketing calls from us you
            may be placed on our internal Do Not Call List by contacting us at
            (307) 459-5078;{" "}
            <a href="https://elitemarketforce.com/contact-us/">
              www.elitemarketforce.com/contact-us/
            </a>
            ; 109 E 17th Street #25, Cheyenne, WY 82001. You may also contact
            the Nevada Attorney General's office: Bureau of Consumer Protection,
            Office of the Nevada Attorney General, 555 E. Washington St., Suite
            3900, Las Vegas, NV 89101; telephone number: 1-702-486-3132; email
            BCPINFO@ag.state.nv.us or AgInfo@ag.nv.gov.
          </p>
          <p>
            <strong>VT:</strong> If you are consumer with a Vermont mailing
            address, we will not share information we collect about you with
            companies outside of Elite Market Force, LLC, unless the law allows.
            For example, we may share information with your consent, to service
            your accounts or under joint marketing agreements with other
            financial institutions with which we have joint marketing
            agreements. We will not share information about your
            creditworthiness within our corporate family except with your
            consent, but we may share information about our transactions or
            experiences with you within our corporate family without your
            consent.
          </p>
          <p>
            <strong>CA:</strong> If you are a consumer with a California mailing
            address, we will not share information we collect about you with
            companies outside of Elite Market Force, LLC, unless the law allows.
            For example, we may share information with your consent, to service
            your accounts, or to provide rewards or benefits to which you are
            entitled.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsumerPrivacy;
