import React, { useState, useEffect } from "react";
import { Stack, Typography, Box, Input } from "@mui/material";
import { useFormContext } from "react-hook-form";
import LinearWithValueLabel from "./utils/LinearWithValueLabel";
import { TextFieldController } from "./utils/TextFieldController";
import { YesNoRadioController } from "./utils/YesNoRadioController";
import { useSearchParams } from "react-router-dom";
import GtagEvent from "./utils/GtagEvent";

export const LeadFormPii3 = (props) => {
  const [searching, setSearching] = useState(true);
  const [searchParams] = useSearchParams();
  const { getValues, setValue } = useFormContext();
  const fname = getValues("fname");
  const lname = getValues("lname");

  const onSearchingChanged = (status) => {
    setSearching(status);
    props.onChangeSearching(status);
  };

  useEffect(() => {
    if (searchParams.get("city") !== null)
      setValue("city", searchParams.get("city"));
    if (searchParams.get("state") !== null)
      setValue("state", searchParams.get("state"));
  }, [searchParams, setValue]);

  return (
    <React.Fragment>
      <Typography align="center" sx={{ p: 1 }}>
        Starting resource search for:{" "}
        <b>
          {fname} {lname}
        </b>
      </Typography>
      <Stack alignItems="center" justifyContent="center">
        <Typography sx={{ p: 1 }}>Searching Resources...</Typography>
        {searching && (
          <Box sx={{ width: "60%" }}>
            <LinearWithValueLabel searching={onSearchingChanged} />
          </Box>
        )}
        {!searching && (
          <React.Fragment>
            <Typography
              align="center"
              color="forestgreen"
              sx={{ p: 1, border: 1, borderRadius: "6px" }}
            >
              <b>UNCLAIMED CASH, GRANTS AND OTHER BENEFITS FOUND!</b>
            </Typography>
            <Typography align="center" sx={{ p: 2 }}>
              <b>To see all benefits you may qualify for</b>, please provide the
              following additional info:
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              justifyContent="center"
              sx={{ m: 1 }}
            >
              <TextFieldController
                name="address"
                label="Street Address"
                defaultValue={props.defaultValues.address}
              />
              <TextFieldController
                name="zip"
                label="Zip Code"
                defaultValue={props.defaultValues.zip}
              />
            </Stack>
            <Input
              type="hidden"
              name="city"
              defaultValue={props.defaultValues.city}
            />
            <Input
              type="hidden"
              name="state"
              defaultValue={props.defaultValues.state}
            />
            <Stack>
              <YesNoRadioController
                name="homeowner"
                label="Are you a homeowner?"
                defaultValue={props.defaultValues.homeowner}
                sx={{ flexDirection: "row", justifyContent: "flex-end" }}
              />
              <YesNoRadioController
                name="edu"
                label="Are you interested in going back to school?"
                defaultValue={props.defaultValues.edu}
                sx={{ flexDirection: "row", justifyContent: "flex-end" }}
              />
              <YesNoRadioController
                name="unemployed"
                label="Are you currently unemployed?"
                defaultValue={props.defaultValues.unemployed}
                sx={{ flexDirection: "row", justifyContent: "flex-end" }}
              />
              <YesNoRadioController
                name="disability"
                label="Do you have a disability?"
                defaultValue={props.defaultValues.disability}
                sx={{ flexDirection: "row", justifyContent: "flex-end" }}
              />
              <YesNoRadioController
                name="debt"
                label="Do you have more than $15K of unsecured debt?"
                defaultValue={props.defaultValues.debt}
                sx={{ flexDirection: "row", justifyContent: "flex-end" }}
              />
            </Stack>
          </React.Fragment>
        )}
      </Stack>
      <img
        src="https://c.usabenefitfinder.com/adsub.php"
        style={{ display: "none" }}
        alt=""
      />
      <img
        src="https://lab.securtrk.com/adsub.php"
        style={{ display: "none" }}
        alt=""
      />
      <iframe
        src="https://www.conversionpx.com/?id=1G4A5AGA9E4GBDA0C565&fetch=2&value=0"
        style={{ display: "none" }}
        title="conversionpx"
      ></iframe>
      <GtagEvent type="event" sendTo="AW-11087024928/tD1YCL2p64oYEKCm2qYp" />
      {/* <Typography>Debug: {phone}</Typography> */}
    </React.Fragment>
  );
};
