import { useEffect } from "react";

const Jornaya = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `(function() {
        var s = document.createElement('script');
        s.id = 'LeadiDscript_campaign';
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//create.lidstatic.com/campaign/bf4bc2c4-49e6-7e65-8432-2460df59f0d8.js?snippet_version=2';
        var LeadiDscript = document.getElementById('LeadiDscript');
        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
        })();`;

    script.id = "LeadiDscript";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return null;
};

export default Jornaya;
