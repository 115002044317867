import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const YesNoRadioController = ({ ...rest }) => {
  const { sx } = rest;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={rest.defaultValue}
      render={({ field, fieldState: { invalid, error } }) => (
        <FormControl sx={sx}>
          <FormLabel
            id={`${rest.name}-label`}
            sx={{
              textAlign: "center",
              color: invalid ? "#d32f2f" : "black",
              p: 1.4,
            }}
          >
            {rest.label}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby={`${rest.name}-label`}
            name={rest.name}
            label={rest.label}
            sx={{ flexWrap: "nowrap" }}
            {...field}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
          {invalid && (
            <FormHelperText error id={`${rest.name}-error`} sx={{ m: 1.4 }}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {...rest}
    />
  );
};
